import React, { useEffect, useState } from 'react';

const ArticlesComponent = () => {
    const [allArticles, setAllArticles] = useState([]);
    const [articles, setArticles] = useState([]); // Paginated articles
    const [currentPage, setCurrentPage] = useState(1);
    const [totalArticles, setTotalArticles] = useState(0);
    const [newArticle, setNewArticle] = useState('');
    const [expandedArticle, setExpandedArticle] = useState(null);
    const [assignedCategories, setAssignedCategories] = useState({});
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [expandedCategories, setExpandedCategories] = useState({});
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredArticles, setFilteredArticles] = useState([]);
    const perPage = 20; // Default pagination per page count

    // Fetch all articles for searching
    const fetchAllArticles = async () => {
        try {
            const response = await fetch(`https://wikiguessr-6d5a3c1aebf6.herokuapp.com/api/articles?all=true`);
            const data = await response.json();
            setAllArticles(data.articles || []);
        } catch (error) {
            console.error('Error fetching all articles:', error);
        }
    };

    // Fetch paginated articles for display
    const fetchArticles = async (page, customPerPage = perPage) => {
        const offset = (page - 1) * customPerPage;
        try {
            const response = await fetch(`https://wikiguessr-6d5a3c1aebf6.herokuapp.com/api/articles?limit=${customPerPage}&offset=${offset}`);
            const data = await response.json();
            setArticles(data.articles || []);
            setTotalArticles(data.totalArticles || 0);
        } catch (error) {
            console.error('Error fetching articles:', error);
        }
    };

    // Fetch categories
    const fetchCategories = async () => {
        try {
            const response = await fetch('https://wikiguessr-6d5a3c1aebf6.herokuapp.com/api/categories');
            const data = await response.json();
            if (data.categories) {
                setCategories(data.categories);
            }
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    // Handle search input change
    const handleSearchInput = async (e) => {
        const value = e.target.value;
        setSearchQuery(value);

        // Fetch all articles if not already fetched
        if (allArticles.length === 0) {
            await fetchAllArticles();
        }

        if (value.trim() !== '') {
            // Filter from allArticles instead of paginated articles
            setFilteredArticles(
                allArticles.filter((article) =>
                    article.title.toLowerCase().includes(value.toLowerCase())
                )
            );
        } else {
            setFilteredArticles([]);
        }
    };

    const handleCategoryRemove = async (articleId, categoryId) => {
        try {
            const response = await fetch('https://wikiguessr-6d5a3c1aebf6.herokuapp.com/api/remove-article-from-category', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    articleId,
                    categoryId,
                }),
            });

            if (response.ok) {
                alert('Category removed successfully!');
                fetchAssignedCategories(articleId);
            } else {
                alert('Failed to remove category.');
            }
        } catch (error) {
            console.error('Error removing category from article:', error);
            alert('Error removing category.');
        }
    };

    const fetchAssignedCategories = async (articleId) => {
        try {
            const response = await fetch(`https://wikiguessr-6d5a3c1aebf6.herokuapp.com/api/article-categories/${articleId}`);
            const data = await response.json();
            if (data.success) {
                setAssignedCategories((prev) => ({
                    ...prev,
                    [articleId]: data.categories,
                }));
            }
        } catch (error) {
            console.error('Error fetching assigned categories:', error);
        }
    };

    const assignCategoryToArticle = async (articleId) => {
        if (!selectedCategory) {
            alert('Please select a category to assign.');
            return;
        }

        try {
            const response = await fetch('https://wikiguessr-6d5a3c1aebf6.herokuapp.com/api/assign-category', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    articleId,
                    categoryId: selectedCategory,
                }),
            });

            if (response.ok) {
                alert('Category assigned successfully!');
                fetchAssignedCategories(articleId);
            } else {
                alert('Failed to assign category.');
            }
        } catch (error) {
            console.error('Error assigning category to article:', error);
            alert('Error assigning category.');
        }
    };

    const toggleArticleExpand = (articleId) => {
        if (expandedArticle === articleId) {
            setExpandedArticle(null);
        } else {
            setExpandedArticle(articleId);
            fetchAssignedCategories(articleId);
        }
    };

    const toggleCategoryExpand = (articleId, categoryId) => {
        setExpandedCategories((prev) => ({
            ...prev,
            [articleId]: prev[articleId] === categoryId ? null : categoryId,
        }));
    };

    const addArticle = async () => {
        if (newArticle.trim() === '') {
            alert('Article name cannot be empty');
            return;
        }
        try {
            const response = await fetch('https://wikiguessr-6d5a3c1aebf6.herokuapp.com/api/articles', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ name: newArticle }),
            });

            const data = await response.json();
            if (data.success) {
                setNewArticle('');
                fetchArticles(currentPage);
                fetchAllArticles(); // Update all articles for search
            } else {
                console.error('Error adding article:', data.message);
            }
        } catch (error) {
            console.error('Error adding article:', error);
        }
    };

    const removeArticle = async (articleId) => {
        try {
            const response = await fetch(`https://wikiguessr-6d5a3c1aebf6.herokuapp.com/api/articles/${articleId}`, {
                method: 'DELETE',
            });
            const data = await response.json();
            if (data.success) {
                fetchArticles(currentPage);
                fetchAllArticles(); // Update all articles for search
            } else {
                console.error('Error deleting article:', data.message);
            }
        } catch (error) {
            console.error('Error deleting article:', error);
        }
    };

    useEffect(() => {
        fetchArticles(currentPage);
        fetchCategories();
        fetchAllArticles(); // Fetch all articles for searching
    }, [currentPage]);

    // Determine which list of articles to render (search results or paginated)
    const articlesToDisplay = searchQuery.trim() ? filteredArticles : articles;

    return (
        <div>
            <h3>Articles</h3>
            <div className="add-category">
                <input
                    type="text"
                    value={newArticle}
                    onChange={(e) => {
                        setNewArticle(e.target.value);
                        handleSearchInput(e);
                    }}
                    placeholder="Enter article name"
                />
                <button onClick={addArticle}>Add Article</button>
            </div>

            <ul>
                {articlesToDisplay.length > 0 ? (
                    articlesToDisplay.map((article) => (
                        <li
                            key={article.id}
                            onClick={() => toggleArticleExpand(article.id)}
                            className={expandedArticle === article.id ? 'expanded' : ''}
                        >
                            <p><strong>Article:</strong> {article.title}</p>
                            {expandedArticle === article.id && (
                                <div className="expanded-content">
                                    <ul>
                                        <p><strong>Assigned Categories:</strong></p>
                                        {assignedCategories[article.id] && assignedCategories[article.id].length > 0 ? (
                                            assignedCategories[article.id].map((category) => (
                                                <li
                                                    key={category.id}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        toggleCategoryExpand(article.id, category.id);
                                                    }}
                                                    className={expandedCategories[article.id] === category.id ? 'expanded-category' : ''}
                                                >
                                                    {category.category_name}
                                                    {expandedCategories[article.id] === category.id && (
                                                        <button
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleCategoryRemove(article.id, category.id);
                                                            }}
                                                        >
                                                            Remove
                                                        </button>
                                                    )}
                                                </li>
                                            ))
                                        ) : (
                                            <li>No categories assigned.</li>
                                        )}
                                    </ul>
                                    <div className="assign-category">
                                        <select
                                            value={selectedCategory}
                                            onChange={(e) => setSelectedCategory(e.target.value)}
                                            onClick={(e) => e.stopPropagation()}
                                        >
                                            <option value="">Select Category</option>
                                            {categories.map((category) => (
                                                <option key={category.id} value={category.id}>
                                                    {category.category_name}
                                                </option>
                                            ))}
                                        </select>
                                        <button onClick={(e) => {
                                            e.stopPropagation();
                                            assignCategoryToArticle(article.id);
                                        }}>
                                            Assign Category
                                        </button>
                                    </div>
                                </div>
                            )}
                            <button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    removeArticle(article.id);
                                }}
                            >
                                Remove
                            </button>
                        </li>
                    ))
                ) : (
                    <p>No articles found.</p>
                )}
            </ul>

            {/* Only show pagination controls if no search is active */}
            {!searchQuery && (
                <div className="pagination-controls">
                    <button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>
                        Previous
                    </button>
                    <span>Page {currentPage} of {Math.ceil(totalArticles / perPage)}</span>
                    <button onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === Math.ceil(totalArticles / perPage)}>
                        Next
                    </button>
                </div>
            )}
        </div>
    );
};

export default ArticlesComponent;
